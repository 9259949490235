<template>
  <div v-show="imageBlock.customizeStatus">
    <div class="card-header">
      <strong>Block Content</strong>
    </div>

    <div class="card-body">
      <div
        class="d-flex justify-content-center bg-secondary p-3 rounded"
        style="cursor: pointer"
      >
        <div @click="$refs.fileInput.click()">
          <LazyImage
            :src="imageBlock.src"
            :key="imageBlock.src"
            width="100px"
            height="100px"
            alt=""
          />
        </div>

        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="chooseImageBlockFile"
        />
      </div>
    </div>

    <div id="accordion">
      <div class="card-header" id="'headingOne'">
        <strong class="p-3">
          <span>Block Style</span>
          <a
            style="float: right; padding-right: 20px; cursor: pointer"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </a>
        </strong>
      </div>

      <div
        id="collapseOne"
        class="collapse show"
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div class="p-3">
          <strong>Padding:</strong>
          <div class="form-group d-flex mb-0">
            <label for="formControlRange">Left:</label>
            <input
              type="range"
              v-model="imageBlock.style.paddingLeft"
              class="form-control-range d-inline"
              style="width: 80%"
              id="formControlRange"
            />
            <span>
              {{ imageBlock.style.paddingLeft }}
              <strong style="color: #0075ff">px</strong>
            </span>
          </div>
          <div class="form-group d-flex mb-0">
            <label for="formControlRange">Right:</label>
            <input
              type="range"
              v-model="imageBlock.style.paddingRight"
              class="form-control-range d-inline"
              style="width: 80%"
              id="formControlRange"
            />
            <span>
              {{ imageBlock.style.paddingRight }}
              <strong style="color: #0075ff">px</strong>
            </span>
          </div>

          <div class="form-group d-flex mb-0">
            <label for="formControlRange">Top:</label>
            <input
              type="range"
              v-model="imageBlock.style.paddingTop"
              class="form-control-range d-inline"
              style="width: 80%"
              id="formControlRange"
            />
            <span>
              {{ imageBlock.style.paddingTop }}
              <strong style="color: #0075ff">px</strong>
            </span>
          </div>

          <div class="form-group d-flex mb-0">
            <label for="formControlRange">Bottom:</label>
            <input
              type="range"
              v-model="imageBlock.style.paddingBottom"
              class="form-control-range d-inline"
              style="width: 80%"
              id="formControlRange"
            />
            <span>
              {{ imageBlock.style.paddingBottom }}
              <strong style="color: #0075ff">px</strong>
            </span>
          </div>
          <hr />
          <div class="form-group d-flex justify-content-between">
            <strong>Horizontal Align:</strong>
            <div
              class="btn-toolbar justify-content-between"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div class="btn-group" role="group" aria-label="First group">
                <button
                  type="button"
                  @click="imageBlock.style.textAlign = 'left'"
                  :class="
                    imageBlock.style.textAlign == 'left'
                      ? 'btn-primary text-white border-0'
                      : 'btn btn-sm btn-outline-primary'
                  "
                >
                  <i class="fa fa-align-left" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  @click="imageBlock.style.textAlign = 'center'"
                  :class="
                    imageBlock.style.textAlign == 'center'
                      ? 'btn-primary text-white border-0'
                      : 'btn btn-sm btn-outline-primary'
                  "
                >
                  <i class="fa fa-align-center" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  @click="imageBlock.style.textAlign = 'right'"
                  :class="
                    imageBlock.style.textAlign == 'right'
                      ? 'btn-primary text-white border-0'
                      : 'btn btn-sm btn-outline-primary'
                  "
                >
                  <i class="fa fa-align-right" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group d-flex mb-0">
            <strong class="col-7">Background Color:</strong>
            <input
              type="color"
              v-model="imageBlock.style.backgroundColor"
              class="form-control-range d-inline"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageBlock: Object,
  },
  data() {
    return {};
  },
  methods: {
    chooseImageBlockFile(e) {
      const file = e.target.files[0];
      this.imageBlock.src = URL.createObjectURL(file);
    },
  },
};
</script>
